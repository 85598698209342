import React from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { Spotlight } from '@atlaskit/onboarding';
import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import spotlightIllustration from './assets/spotlightImage.svg';

export const READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID = 'cc-reading-aids-highlight-spotlight';

const i18n = defineMessages({
	acronymHighlightSpotlightText: {
		id: 'contextual-reading-aids.acronym-highlight-spotlight.text',
		defaultMessage:
			'Highlight and define any company-specific terms, project names, and jargon without leaving the page!',
		description: 'Description text for spotllight',
	},
	acronymHighlightSpotlightDismissButtonText: {
		id: 'contextual-reading-aids.acronym-highlight-spotlight.dismiss-button.text',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button text for spotlight',
	},
});

type ReadingAidsAcronymSpotlightProps = {
	contentId: string;
	onDismissClick: () => void;
};

export const ReadingAidsAcronymSpotlight = ({
	contentId,
	onDismissClick,
}: ReadingAidsAcronymSpotlightProps) => {
	const intl = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const handleOnClick = () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: 'readingAidsAcronymSpotlightDismissButton',
				source: 'readingAidsAcronymSpotlight',
				attributes: {
					contentId,
				},
			},
		}).fire();
		onDismissClick();
	};

	return (
		<Spotlight
			dialogWidth={275}
			image={spotlightIllustration}
			actions={[
				{
					text: intl.formatMessage(i18n.acronymHighlightSpotlightDismissButtonText),
					onClick: handleOnClick,
				},
			]}
			target={READING_AIDS_ACRONYM_SPOTLIGHT_MESSAGE_ID}
			targetRadius={4}
		>
			{intl.formatMessage(i18n.acronymHighlightSpotlightText)}
		</Spotlight>
	);
};
