import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';

export function handleAnalyticsEvents(objectId: string) {
	return (event: UIAnalyticsEvent) => {
		event.update((payload) => ({
			...payload,
			attributes: {
				...payload.attributes,
				objectId,
			},
		}));
	};
}
