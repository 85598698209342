import { memo, useEffect, useCallback } from 'react';

import type { JSONDocNode } from '@atlaskit/editor-json-transformer';
import type { InlineCommentSelectionComponentProps } from '@atlaskit/editor-common/types';

export type BaseSelectionComponentProps = {
	pageId: string;
	isArchived?: boolean;
	updateDocument?: (doc: JSONDocNode) => void;
};

type SelectionManagerProps = BaseSelectionComponentProps & {
	setSelectionProps: (
		selectionProps: (InlineCommentSelectionComponentProps & BaseSelectionComponentProps) | null,
	) => void;
};

export const SelectionManager = ({
	pageId,
	isArchived,
	setSelectionProps,
	updateDocument,
}: SelectionManagerProps) =>
	memo((props: InlineCommentSelectionComponentProps) => {
		const {
			range,
			draftRange,
			wrapperDOM,
			isAnnotationAllowed,
			onCreate,
			onClose,
			applyDraftMode,
			removeDraftMode,
			inlineNodeTypes,
		} = props;

		const handleClose = useCallback(() => {
			onClose();
			setSelectionProps(null);
		}, [onClose]);

		useEffect(() => {
			setSelectionProps({
				pageId,
				isArchived,
				updateDocument,
				range,
				draftRange,
				wrapperDOM,
				isAnnotationAllowed,
				onCreate,
				onClose: handleClose,
				applyDraftMode,
				removeDraftMode,
				inlineNodeTypes,
			});
			// We only want to listen to changes that signifies a new selection
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [range, draftRange, wrapperDOM]);

		return null;
	});
