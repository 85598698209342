import type { Action } from 'react-sweet-state';
import { createStore, createActionsHook, createStateHook } from 'react-sweet-state';

import type { SelectionRect } from './ReadingAidsPopup';

export type ReadingAidsStateType = {
	portalContainerRef: React.MutableRefObject<HTMLElement | null> | null;
	autohighlightText: string;
	autohighlightContext: string;
	sessionId: string;
	autohighlightSelectionRect: SelectionRect;
	autohighlightHovered: boolean;
	showPopup: boolean;
	popupLoading: boolean;
	showSpotlight: boolean;
	existingMap: Map<string, number>;
	lastAcronymReset: () => void;
};

const initialState: ReadingAidsStateType = {
	portalContainerRef: null,
	autohighlightText: '',
	autohighlightContext: '',
	sessionId: '',
	autohighlightSelectionRect: {
		top: 0,
		left: 0,
		width: 0,
		height: 0,
	},
	autohighlightHovered: false,
	showPopup: false,
	popupLoading: false,
	showSpotlight: false,
	existingMap: new Map(),
	lastAcronymReset: () => {},
};

export const actions = {
	showAutohighlightPopup:
		({
			autohighlightText,
			autohighlightContext,
			autohighlightSelectionRect,
			lastAcronymReset,
			sessionId,
		}: {
			autohighlightText: string;
			autohighlightContext: string;
			autohighlightSelectionRect: SelectionRect;
			lastAcronymReset: () => void;
			sessionId: string;
		}): Action<typeof initialState> =>
		({ setState }) => {
			setState({
				autohighlightText,
				autohighlightContext,
				autohighlightSelectionRect,
				lastAcronymReset,
				sessionId,
				showPopup: true,
				popupLoading: true,
			});
		},
	hideAutohighlightPopup:
		(): Action<typeof initialState> =>
		({ setState, getState }) => {
			getState().lastAcronymReset();
			setState({
				...getState(),
				showPopup: false,
				popupLoading: false,
			});
		},
	setAutohighlightPopupLoaded:
		(): Action<typeof initialState> =>
		({ setState, getState }) => {
			getState().lastAcronymReset();
			setState({
				...getState(),
				popupLoading: false,
			});
		},
	setAutohighlightHovered:
		({ autohighlightHovered }: { autohighlightHovered: boolean }): Action<typeof initialState> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				autohighlightHovered,
			});
		},
	showAutohighlightSpotlight:
		({
			autohighlightSelectionRect,
		}: {
			autohighlightSelectionRect: SelectionRect;
		}): Action<typeof initialState> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				showSpotlight: true,
				autohighlightSelectionRect,
			});
		},
	setPortalContainerRef:
		({
			portalContainerRef,
		}: {
			portalContainerRef: React.MutableRefObject<HTMLElement | null> | null;
		}): Action<typeof initialState> =>
		({ setState, getState }) => {
			setState({
				...getState(),
				portalContainerRef,
			});
		},
};

export const readingAidsStore = createStore<ReadingAidsStateType, typeof actions>({
	initialState,
	actions,
	name: 'readingAidsStore',
});

export const useReadingAidsActions = createActionsHook(readingAidsStore);
export const useReadingAidsState = createStateHook(readingAidsStore);
