import type { VFC } from 'react';

import { expValEquals } from '@confluence/feature-experiments';
import { fg } from '@confluence/feature-gating';
import {
	createSingleQueryParamHook,
	useRouteActions,
} from '@confluence/route-manager/entry-points/RouteState';

const useFocusedMentionIdQueryParam = createSingleQueryParamHook('focusedMentionId');

export const MentionScrollComponent: VFC = () => {
	const focusedMentionId = useFocusedMentionIdQueryParam();
	const { setQueryParams } = useRouteActions();

	if (
		// eslint-disable-next-line confluence-feature-gating/no-preconditioning
		focusedMentionId &&
		expValEquals('confluence_frontend_anchored_mentions_exp', 'cohort', 'experiment') &&
		fg('confluence_frontend_anchored_mentions')
	) {
		const mentionElement = document.querySelector(`[data-local-id="${focusedMentionId}"]`);
		if (mentionElement) {
			mentionElement.scrollIntoView({
				behavior: 'instant',
				inline: 'center',
				block: 'center',
			});
			// Remove the query param immediately after mention scroll is complete
			setQueryParams({
				focusedMentionId: undefined,
			});
		}
	}

	return null;
};
