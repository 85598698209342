import type { ContentUnifiedQueryContentNodeType } from '@confluence/content-unified-query';
import type { ContentPropertiesQueryContentNodeType } from '@confluence/content-body/entry-points/ContentPropertiesQuery';

type propType =
	| Partial<ContentUnifiedQueryContentNodeType | ContentPropertiesQueryContentNodeType>
	| null
	| undefined;

// editor property is expected to be:
//   1. ""    -> auto conversion will happen
//   2. "v2"  -> explicitly using fabric editor
//   3. "v1"  -> explicitly using tinyMCE editor
// when parsing editor property, if neither of the above data is received,
// then it's an unexpected case and we set it as "unset" for analytics.
function parseEditorProperty(value: string | null | undefined) {
	if (!value) {
		return '';
	}
	if (value?.includes('v2')) {
		return 'v2';
	}
	if (value?.includes('v1')) {
		return 'v1';
	}
	return 'unset';
}

export function getAutoConvertionInfo(content: propType) {
	const contentProperties = content?.properties?.nodes;
	const editorInfo = contentProperties?.filter((p: any) => p?.key === 'editor');
	const editorPropertieValueRaw = editorInfo?.[0]?.value;
	const editorProperty = parseEditorProperty(editorPropertieValueRaw);
	if (
		content &&
		'metadata' in content &&
		content?.metadata?.frontend &&
		'fabricEditorSupported' in content.metadata.frontend
	) {
		return {
			queryDataId: content?.id,
			editorProperty,
			fabricEditorSupported: content?.metadata?.frontend?.fabricEditorSupported,
		};
	}
	return {
		queryDataId: content?.id,
		editorProperty,
		fabricEditorSupported: null,
	};
}
