import { useEffect, useState } from 'react';
import { NetworkStatus } from 'apollo-client';
import type { ApolloClient, ApolloError, QueryOptions } from 'apollo-client';
import type { OperationVariables } from '@apollo/react-common';
import type { DocumentNode } from 'graphql';

import { getApolloClient } from './getApolloClient';

type QueryNoFurtherUpdateResult<TData = any> = {
	data: TData | undefined;
	error?: ApolloError;
	loading: boolean;
	networkStatus: NetworkStatus;
};

const queryResults = new WeakMap<
	DocumentNode,
	Map<string, Promise<QueryNoFurtherUpdateResult<any>>>
>();

/**
 * This hook works like `useQuery` but it will not update the result after the initial fetch.
 * Essentially it works like fetchPolicy: "standby". See https://www.apollographql.com/docs/react/data/queries/#standby
 *
 * The difference between this hook and `useQuery` is it internally uses pure promise instead of Observable.
 * In the case a query is frequent fetched this hook performs much faster than the Apollo's useQuery.
 * If other fetchPolicy is needed, use `useQuery` instead.
 */
export function useQueryNoFurtherUpdate<TData = any, TVariables = OperationVariables>(
	query: DocumentNode,
	options?: Omit<QueryOptions<TVariables>, 'query' | 'fetchPolicy'> & {
		fetchPolicy?: 'standby';
		skip?: boolean;
		client?: ApolloClient<any>;
	},
): QueryNoFurtherUpdateResult<TData | null> {
	const client = options?.client ?? getApolloClient();
	let dataFromCache: TData | null = null;
	try {
		if (!options?.skip) {
			dataFromCache = client.readQuery<TData, TVariables>({
				query,
				...options,
			});
		}
	} catch {}

	const [result, setResult] = useState<QueryNoFurtherUpdateResult<TData | null>>(() => {
		return dataFromCache
			? {
					data: dataFromCache,
					loading: false,
					networkStatus: NetworkStatus.ready,
				}
			: {
					data: null,
					loading: true,
					networkStatus: NetworkStatus.loading,
				};
	});

	useEffect(() => {
		if (result.loading && !options?.skip) {
			// technically not a hash but it's good enough since variable is an plain object.
			const variablesHash = JSON.stringify(options?.variables || {});

			// Same query + variable should reuse the same promise to avoid going through expensive Apollo client.
			let queryResultPromise: Promise<QueryNoFurtherUpdateResult<TData>>;
			if (queryResults.has(query) && queryResults.get(query)!.has(variablesHash)) {
				queryResultPromise = queryResults.get(query)?.get(variablesHash)!;
			} else {
				queryResultPromise = client.query<TData, TVariables>({
					query,
					...options,
				});
				if (!queryResults.has(query)) {
					queryResults.set(query, new Map());
				}
				queryResults.get(query)!.set(variablesHash, queryResultPromise);
			}

			queryResultPromise
				.then((result) => setResult(result))
				.catch((e): void => {
					setResult({
						...result,
						loading: false,
						networkStatus: NetworkStatus.ready,
						error: e,
					});
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return result;
}
