import type { ContentUnifiedQueryMacroRenderedOutputType } from '@confluence/content-unified-query';

const MACRO_REGEX = /data-macro-name="(.+?)"/;
/**
 * ContentQuery includes macroRenderedOutput which contains all macros used in SSR.
 * When a page has duplicate macros the css and js web resources are added twice to DOM.
 * To prevent this we need to transform macroRenderedOutput to remove duplicate resources
 */
export const removeDuplicateResourcesForMacros = (
	macroRenderedOutput: (ContentUnifiedQueryMacroRenderedOutputType | null)[],
): (ContentUnifiedQueryMacroRenderedOutputType | null)[] => {
	const parsedMacros = new Set();
	macroRenderedOutput?.forEach((macro) => {
		const macroName = macro?.value?.value?.match(MACRO_REGEX);

		if (!macroName) {
			return;
		}

		if (parsedMacros.has(macroName[1])) {
			const tags = macro?.value?.webresource?.tags;
			if (tags) {
				tags.js = '';
			}
		} else {
			parsedMacros.add(macroName[1]);
		}
	});
	return macroRenderedOutput;
};
