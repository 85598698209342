import { memo, useEffect, useCallback } from 'react';

import type { InlineCommentHoverComponentProps } from '@atlaskit/editor-common/types';

import type { HoverComponentProps } from './HoverComponent';

type HoverManagerProps = HoverComponentProps & {
	setHoverProps: (
		hoverProps: (InlineCommentHoverComponentProps & HoverComponentProps) | null,
	) => void;
};

export const HoverManager = ({
	pageId,
	updateDocument,
	isArchived,
	setHoverProps,
}: HoverManagerProps) =>
	memo((props: InlineCommentHoverComponentProps) => {
		const {
			range,
			isWithinRange,
			wrapperDOM,
			isAnnotationAllowed,
			onCreate,
			onClose,
			applyDraftMode,
			removeDraftMode,
		} = props;

		const handleClose = useCallback(() => {
			onClose();
			setHoverProps(null);
		}, [onClose]);

		useEffect(() => {
			setHoverProps({
				pageId,
				updateDocument,
				isArchived,
				range,
				wrapperDOM,
				isAnnotationAllowed,
				onCreate,
				onClose: handleClose,
				applyDraftMode,
				removeDraftMode,
				isWithinRange,
			});
			// We only want to listen to changes that signifies a new selection
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [range, wrapperDOM, isWithinRange]);

		return null;
	});
