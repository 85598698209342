import type { FC } from 'react';
import React, { memo, useEffect, useContext, useRef } from 'react';

import { AnalyticsListener } from '@atlaskit/analytics-next';

import { VIEW_PAGE } from '@confluence/named-routes';
import { RoutesContext } from '@confluence/route-manager/entry-points/RoutesContext';
import { getUniquePageLoadId } from '@confluence/unique-page-load-id';

import { MEDIA_METRIC } from './perf.config';

type MediaAnalyticsRef = {
	current: MediaAnalyticsRefCurrent;
};

type MediaAnalyticsRefCurrent = {
	succeededSPAMedia: number;
	waitingSPAMedia: number;
	failedSPAMedia: number;
	succeededSSRMedia: number;
	waitingSSRMedia: number;
	failedSSRMedia: number;
	mediaFileIds: string[] | null;
	ssrFmpStarted: boolean;
	spaFmpStarted: boolean;
	longestTTIDuration: number | null;
	longestSSRMediaLoadTime: number | null;
	pageLoadInfo: any | null;
};

const mediaAnalyticsRefDefault = {
	succeededSPAMedia: 0,
	waitingSPAMedia: 0,
	failedSPAMedia: 0,
	succeededSSRMedia: 0,
	waitingSSRMedia: 0,
	failedSSRMedia: 0,
	mediaFileIds: null,
	ssrFmpStarted: false,
	spaFmpStarted: false,
	longestTTIDuration: null,
	longestSSRMediaLoadTime: null,
	pageLoadInfo: null,
};

const clearMediaSourcesAndRef = (mediaAnalyticsRef: MediaAnalyticsRef) => {
	if ((window as any)._contentMediaSources) {
		(window as any)._contentMediaSources = null;
	}
	mediaAnalyticsRef.current = mediaAnalyticsRefDefault;
};

const handleMediaMetricStop = (mediaAnalyticsRef: MediaAnalyticsRef) => {
	const {
		succeededSPAMedia,
		waitingSPAMedia,
		failedSPAMedia,
		succeededSSRMedia,
		waitingSSRMedia,
		failedSSRMedia,
		spaFmpStarted,
		longestTTIDuration,
	} = mediaAnalyticsRef.current;
	MEDIA_METRIC.stop({
		stopTime: longestTTIDuration || undefined,
		customData: {
			succeededSPAMedia,
			waitingSPAMedia,
			failedSPAMedia,
			succeededSSRMedia,
			waitingSSRMedia,
			failedSSRMedia,
			spaRenderedBeforeSSRMedia: spaFmpStarted,
			pageLoadInfo: getUniquePageLoadId() as any,
		},
	});
	clearMediaSourcesAndRef(mediaAnalyticsRef);
};

const updateSPAMetricsAndFileIds = (
	duration: number,
	isSucceededEvent: boolean,
	fileIdIndex: number,
	mediaAnalyticsRef: MediaAnalyticsRef,
) => {
	const { mediaFileIds, succeededSPAMedia, failedSPAMedia, longestTTIDuration } =
		mediaAnalyticsRef.current;
	const tempMediaFileIds = [...(mediaFileIds || [])];
	tempMediaFileIds.splice(fileIdIndex, 1);

	mediaAnalyticsRef.current = {
		...mediaAnalyticsRef.current,
		mediaFileIds: tempMediaFileIds,
		succeededSPAMedia: isSucceededEvent ? succeededSPAMedia + 1 : succeededSPAMedia,
		waitingSPAMedia: tempMediaFileIds.length,
		failedSPAMedia: !isSucceededEvent ? failedSPAMedia + 1 : failedSPAMedia,
		longestTTIDuration:
			longestTTIDuration && longestTTIDuration > duration ? longestTTIDuration : duration,
	};
	if (tempMediaFileIds.length === 0) {
		handleMediaMetricStop(mediaAnalyticsRef);
	}
};

const incrementSuccessOrFailureValue = (entryName: string, value: number) => {
	(window as any)._contentMediaSources.forEach((source: any) => {
		if (source === entryName) {
			value++;
		}
	});
	return value;
};

const captureSSRMediaResourceTimings = () => {
	let succeededSSRMedia = 0;
	let failedSSRMedia = 0;
	let waitingSSRMedia = 0;
	let longestSSRMediaLoadTime = 0;
	if ((window as any)?._contentMediaSources?.length) {
		const resourceEntries = performance.getEntriesByType('resource');
		if (resourceEntries && resourceEntries.length > 0) {
			resourceEntries.forEach((entry: any) => {
				const value = 'initiatorType' in entry;
				if (
					value &&
					entry?.initiatorType === 'img' &&
					(window as any)._contentMediaSources.indexOf(entry?.name) > -1
				) {
					// Duration will be 0 in some failure cases so we want to check for that,
					// and we also want to make sure responseEnd is available and non-zero.
					if (entry.duration && entry.responseEnd) {
						succeededSSRMedia = incrementSuccessOrFailureValue(entry.name, succeededSSRMedia);
						longestSSRMediaLoadTime =
							entry.responseEnd > longestSSRMediaLoadTime
								? entry.responseEnd
								: longestSSRMediaLoadTime;
					} else {
						failedSSRMedia = incrementSuccessOrFailureValue(entry.name, failedSSRMedia);
					}
				}
			});
		}
		waitingSSRMedia =
			(window as any)._contentMediaSources.length - (succeededSSRMedia + failedSSRMedia);
	}
	return {
		succeededSSRMedia,
		failedSSRMedia,
		waitingSSRMedia,
		longestSSRMediaLoadTime,
	};
};

const startMediaMetric = (mediaAnalyticsRef: MediaAnalyticsRef) => {
	const { succeededSSRMedia, failedSSRMedia, waitingSSRMedia, longestSSRMediaLoadTime } =
		captureSSRMediaResourceTimings();
	MEDIA_METRIC.startFromPageLoad();
	if (succeededSSRMedia && !waitingSSRMedia && !failedSSRMedia) {
		// Mark FMP as longest SSR media load time if all media successfully SSR'd
		MEDIA_METRIC.markFMP(longestSSRMediaLoadTime);
		mediaAnalyticsRef.current.ssrFmpStarted = true;
	} else {
		mediaAnalyticsRef.current.spaFmpStarted = true;
	}
	mediaAnalyticsRef.current = {
		...mediaAnalyticsRef.current,
		succeededSSRMedia,
		failedSSRMedia,
		waitingSSRMedia,
		longestSSRMediaLoadTime,
	};
};

const handleSucceededOrFailedEvent = (
	duration: number,
	isSucceededEvent: boolean,
	fileIdIndex: number,
	mediaAnalyticsRef: MediaAnalyticsRef,
) => {
	if (!mediaAnalyticsRef.current.ssrFmpStarted && !mediaAnalyticsRef.current.spaFmpStarted) {
		startMediaMetric(mediaAnalyticsRef);
	}
	updateSPAMetricsAndFileIds(duration, isSucceededEvent, fileIdIndex, mediaAnalyticsRef);
};

// Convert undefined values to number (-1) when doing index lookup.
const safeGetFileIdIndex = (event: any, mediaAnalyticsRef: MediaAnalyticsRef) => {
	const fileIdIndex = mediaAnalyticsRef.current.mediaFileIds?.indexOf(
		event.payload?.attributes?.fileAttributes?.fileId,
	);
	return typeof fileIdIndex === 'undefined' ? -1 : fileIdIndex;
};

const handleMediaEvent = (event: any, mediaAnalyticsRef: MediaAnalyticsRef) => {
	const fileIdIndex = safeGetFileIdIndex(event, mediaAnalyticsRef);
	if (
		window.__SSR_RENDERED__ &&
		event.payload.actionSubject === 'mediaCardRender' &&
		fileIdIndex > -1
	) {
		const isSucceededEvent = event.payload?.action === 'succeeded';
		const duration =
			event.payload?.attributes?.performanceAttributes?.overall?.durationSincePageStart;
		if (isSucceededEvent || event.payload.action === 'failed') {
			const succeededOrFailedDuration = isSucceededEvent ? duration : 0;
			handleSucceededOrFailedEvent(
				succeededOrFailedDuration,
				isSucceededEvent,
				fileIdIndex,
				mediaAnalyticsRef,
			);
		}
	}
};

const parseAndUpdateMediaFileIds = (mediaAnalyticsRef: MediaAnalyticsRef) => {
	const FILE_SEARCH_TERM = 'file/';
	const IMAGE_SEARCH_TERM = '/image';
	const mediaFileIds = (window as any)._contentMediaSources
		.map((source: string) => {
			const fileIndex = source.indexOf(FILE_SEARCH_TERM);
			const imageIndex = source.indexOf(IMAGE_SEARCH_TERM);
			if (fileIndex > -1 && imageIndex > -1) {
				// Return the fileId that will be between keywords 'file/' and '/image' in the url.
				return source.substring(fileIndex + FILE_SEARCH_TERM.length, imageIndex);
			} else {
				return null;
			}
		})
		.filter((id: string) => id);
	mediaAnalyticsRef.current = { ...mediaAnalyticsRef.current, mediaFileIds };
};

export const MediaAnalytics: FC<{
	contentId: string;
	children?: React.ReactNode;
}> = memo(({ contentId, children }) => {
	const { match } = useContext(RoutesContext);
	const isViewPage = match?.name === VIEW_PAGE.name;

	const mediaAnalyticsRef = useRef<MediaAnalyticsRefCurrent>(mediaAnalyticsRefDefault);

	// This useEffect handles media analytics on initial load (SSR'd content)
	// as well as cleaning up state on page to page transitions.
	useEffect(() => {
		const isTransition = contentId && !window.__SSR_RENDERED__;

		if (
			isViewPage &&
			window.__SSR_RENDERED__ &&
			(window as any)?._contentMediaSources?.length > 0 &&
			mediaAnalyticsRef.current.mediaFileIds === null
		) {
			parseAndUpdateMediaFileIds(mediaAnalyticsRef);
		} else if (isViewPage && isTransition) {
			clearMediaSourcesAndRef(mediaAnalyticsRef);
		}
	}, [isViewPage, contentId]);

	// This useEffect handles clearing media sources on unmount.
	useEffect(() => {
		return () => clearMediaSourcesAndRef(mediaAnalyticsRef);
	}, []);

	return (
		<AnalyticsListener
			channel="media"
			onEvent={(e) => {
				handleMediaEvent(e, mediaAnalyticsRef);
			}}
		>
			{children}
		</AnalyticsListener>
	);
});
